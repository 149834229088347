import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useGlobalText from '@hooks/useGlobalText'

import Icon from '@objects/icon'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  tocListRoot: {
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      position: 'sticky',
      top: theme.spacing(30),
    },
  },
  tocList: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  item: {
    padding: theme.spacing(0, 0, 0, 10),
    margin: theme.spacing(4, 0),
    color: theme.palette.text.primary,
    fontWeight: 500,
    transitionProperty: 'all',
    fontFamily: theme.typography.fontFamily2,
    transitionDuration: theme.transitions.duration.standard as string,
    transitionTimingFunction: theme.transitions.easing.easeInOut as string,
    '&:first-child': {
      marginTop: 0,
      '& $marker': {
        top: 0,
        transform: 'none',
      },
    },
    '&:last-child': {
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 4, 14),
      margin: 0,
    },
  },
  link: {
    '&:focus': {
      color: theme.palette.text.primary,
      textDecoration: 'underline',
    },
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'none',
    },
  },
  activebefore: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(25),
    },
  },
  active: {
    position: 'relative',
    // fontWeight: 700,
    margin: theme.spacing(12, 0),
    padding: `0px 0px 0px ${theme.spacing(6)}px`,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(30),
    },
  },
  marker: {
    position: 'absolute',
    height: '100px',
    width: '8px',
    borderRadius: '6px',
    backgroundColor: theme.palette.primary.main,
    left: 0,
    top: 0,
    transition: '.15s ease-in-out',
  },
  markerTrack: {
    height: '100%',
    backgroundColor: theme.palette.background.light,
    width: '8px',
    position: 'absolute',
    top: 0,
    borderRadius: '4px',
  },
  icon: {
    fontSize: theme.spacing(3),
    margin: theme.spacing(0, 2, 0, -5),
    verticalAlign: 'baseline',
    color: theme.palette.primary.main,
  },
}))

function ToCList({ className, items, position, clickHandler }) {
  const theme = useTheme()
  const classes = useStyles()
  const { getText } = useGlobalText()
  const activeItem = useRef(null)
  const navList = useRef(null)
  const [markerTop, setMarkerTop] = useState(0)
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    const listItems = navList.current.children
    const itemHeight = activeItem.current?.getBoundingClientRect().height

    let offsetTop = 0

    for (let i = 0; i < position; i++) {
      offsetTop += listItems[i].getBoundingClientRect().height
    }

    if (position > 0) {
      // add margin/ spacing
      if (isLarge) {
        offsetTop += theme.spacing(25)
      } else {
        offsetTop += position * theme.spacing(4) + theme.spacing(7)
      }
      // center indicator
    }
    offsetTop = offsetTop - (100 - itemHeight) / 2
    setMarkerTop(offsetTop)
  }, [position])

  function getPosition(element) {
    let yPosition = 0

    while (element) {
      yPosition += element.offsetTop - element.scrollTop + element.clientTop
      element = element.offsetParent
    }

    return yPosition - (isLarge ? 120 : 60)
  }

  function itemClickHandler(ev, item) {
    ev.preventDefault()
    if (clickHandler) clickHandler()
    window.scrollTo({
      top: getPosition(document.getElementById(item.id)),
      left: 0,
      behavior: 'smooth',
    })
  }

  function renderItems() {
    if (!items) return
    return items.map((item, i) => {
      return (
        item.id && (
          <li
            key={`tocItem-${item.id}`}
            ref={position === i ? activeItem : null}
            className={clsx(classes.item, {
              [classes.activebefore]: position - 1 === i,
              [classes.active]: position === i,
            })}
          >
            <a
              className={classes.link}
              href={`#${item.id}`}
              onClick={(ev) => itemClickHandler(ev, item)}
            >
              {position < i && (
                <Icon
                  className={classes.icon}
                  name="ArrowDown"
                  size="inherit"
                />
              )}
              {position > i && (
                <Icon className={classes.icon} name="ArrowUp" size="inherit" />
              )}
              {item.label}
            </a>
          </li>
        )
      )
    })
  }

  return (
    <nav
      data-testid="list"
      className={clsx(className, classes.tocListRoot)}
      aria-label={getText('tocIntro')}
    >
      <ol ref={navList} className={classes.tocList} role="navigation">
        {renderItems()}
      </ol>
      <div className={classes.markerTrack} />
      <div className={classes.marker} style={{ top: `${markerTop}px` }} />
    </nav>
  )
}

ToCList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  position: PropTypes.number,
  clickHandler: PropTypes.func,
}

export default ToCList
