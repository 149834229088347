import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { graphql } from 'gatsby'
import { ArticleJsonLd, SpeakableJsonLd } from 'gatsby-plugin-next-seo'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { useLocation } from '@reach/router'

import useDateFns from '@hooks/useDateFns'
import useGlobalText from '@hooks/useGlobalText'

import Container from '@objects/container'
import Copy from '@components/copy'
import SocialShareBar from '@components/socialShareBar'
import ToC from '@components/toc'
import { createTocList } from '@tools/componentParser'
import Stage from '@components/stage'
import TeaserRow from '@components/teaserRow'
import Paragraph from '@objects/paragraph'
import Icon from '@objects/icon'
import { ArticlePageProps } from '@templates/article-page'

const useStyles = makeStyles((theme) => ({
  dateReadingTimeWrapper: {
    marginBottom: theme.spacing(5),
  },
  readingTimeIcon: {
    fontSize: '22px',
    margin: '0 5px 2px -4px',
  },
  readingTimeCopy: {
    fontSize: '11px',
    fontWeight: 600,
  },
  date: {
    fontSize: '14px',
    marginRight: theme.spacing(2),
    marginBottom: 0,
  },
  articleBottomMargin: {
    marginBottom: theme.spacing(12),
  },
}))

export default function RootIndex({ data }: ArticlePageProps): ReactElement {
  console.log('data', data)
  const { formatDate } = useDateFns()
  const article = data.contentfulArticlePage
  const location = useLocation()
  const path = location?.origin + location?.pathname
  const classes = useStyles()
  const { getText } = useGlobalText()
  const intl = useIntl()
  const articleintro = article.intro?.intro
  const tocItems = articleintro
    ? [
        {
          id: intl
            .formatMessage({ id: 'article.intro' })
            .replace(/\s+/g, '')
            .toLowerCase(),
          label: intl.formatMessage({ id: 'article.intro' }),
        },
      ].concat(createTocList(article.copy))
    : createTocList(article.copy)

  function renderArticleBody() {
    return (
      <Container
        type="article"
        className={
          !!article.hideTableOfContent ? classes.articleBottomMargin : ''
        }
      >
        <Grid
          className={classes.dateReadingTimeWrapper}
          container
          spacing={1}
          alignItems="center"
        >
          {!article.hideTableOfContent &&
            (article.publishdate || article.updatedAt) && (
              <Grid item>
                <span className={classes.date}>
                  {article.publishdate
                    ? formatDate(article.publishdate, 'dd. MMMM y')
                    : article.updatedAt
                    ? formatDate(article.updatedAt, 'dd. MMMM y')
                    : null}
                </span>
                {!!article.showCustomUpdatedDate && (
                  <span className={classes.date}>
                    |{' '}
                    {!!article.customUpdatedDateTitle
                      ? article.customUpdatedDateTitle
                      : 'aktualisiert am'}{' '}
                    {!!article.customUpdatedDate
                      ? formatDate(article.customUpdatedDate, 'dd. MMMM y')
                      : article.publishdate
                      ? formatDate(article.publishdate, 'dd. MMMM y')
                      : article.updatedAt
                      ? formatDate(article.updatedAt, 'dd. MMMM y')
                      : null}{' '}
                    |
                  </span>
                )}
              </Grid>
            )}
          {article.readingTime && (
            <span className={classes.date}>
              <Icon className={classes.readingTimeIcon} name="Duration" />
              <span className={classes.readingTimeCopy}>
                {article.readingTime}
              </span>
            </span>
          )}
        </Grid>
        {articleintro && (
          <Paragraph bold ariaRole="paragraph">
            {articleintro}
          </Paragraph>
        )}
        {article.copy && (
          <Copy type="article" richtext={article.copy} ariaRole="article" />
        )}
      </Container>
    )
  }

  return (
    <React.Fragment>
      <ArticleJsonLd
        url={path}
        headline={article.titleArticle}
        datePublished={article.publishdate}
        overrides={{
          '@type': 'NewsArticle',
        }}
      />
      <SpeakableJsonLd cssSelector={['article']} />

      <Stage
        type={article.hideTableOfContent ? 'press' : 'default'}
        headline={article.titleArticle}
        copy={article.teaserCopy}
        image={article.teaserImage}
        imageMobile={article?.teaserImageMobile}
        imageFocalPoint={article.teaserImageFocalPoint}
        articleDate={formatDate(
          article.publishdate || article.updatedAt,
          'dd. MMMM y'
        )}
      />
      {!article.hideTableOfContent && (
        <ToC items={tocItems}>{renderArticleBody()}</ToC>
      )}
      {!!article.hideTableOfContent && renderArticleBody()}
      {article.relatedContent?.length > 0 && (
        <TeaserRow
          type="Default"
          theme="Dark"
          headline={
            article.relatedContentHeadline ||
            getText('relatedContentDefaultHeadline')
          }
          relatedContent={article.relatedContent}
        />
      )}
      <SocialShareBar headline={article.titleArticle} />
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query {
    contentfulArticlePage(id: { eq: "3fb6fe54-99b6-5227-9e49-4de30ecfbd3a" }) {
      titleArticle
      hideTableOfContent
      teaserImage {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      teaserImageMobile {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      teaserImageFocalPoint
      intro {
        intro
      }
      teaserCopy {
        raw
      }
      copy {
        raw
        references {
          ...ContentfulArticleFragment
        }
      }
      readingTime
      customUpdatedDateTitle
      publishdate(formatString: "DD.MMMM.Y")
      customUpdatedDate(formatString: "DD.MMMM.Y")
      showCustomUpdatedDate
      updatedAt(formatString: "DD.MMMM.Y")
      relatedContentHeadline
      relatedContent {
        ...ContentfulArticleRelatedFragment
      }
    }
  }
`
