import React, { ReactElement, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Hidden, Fade } from '@material-ui/core'
import { useLocation } from '@reach/router'

import Button from '@objects/button'

import useGlobalText from '@hooks/useGlobalText'
import { useIntl } from 'react-intl'

export type SocialShareBarProps = RvG.IReactDefaultProps & {
  headline: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.appBar,
  },
  socialIcon: {
    margin: '1px 0',
  },
}))

export default function SocialShareBar({
  headline,
}: SocialShareBarProps): ReactElement {
  const location = useLocation()
  const { getText } = useGlobalText()
  const currentUrl = location.href
  const classes = useStyles()
  const [isVisible, setVisibility] = useState(false)
  const shareButtonSubject = getText('shareButtonSubject')
  const shareButtonBody = getText('shareButtonBody')

  function toggleVisibility() {
    if (window.pageYOffset > 50) {
      setVisibility(true)
    } else {
      setVisibility(false)
    }
  }

  function openPopup(url: string) {
    window.open(
      url,
      '',
      'menubar=no, toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    )
    return false
  }

  function mail() {
    const subject = `${shareButtonSubject} ${headline}`
    const body = `${shareButtonBody} ${currentUrl}`
    const aTag = document.createElement('a')
    aTag.setAttribute('href', `mailto:?subject=${subject}&body=${body}`)
    aTag.click()
  }

  useEffect(() => {
    setVisibility(false)
    document.addEventListener('scroll', toggleVisibility)
    return () => {
      document.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  const intl = useIntl()

  return (
    <Hidden mdDown>
      <aside aria-label="Social Media Links">
        <Fade in={isVisible} timeout={1000}>
          <div className={classes.root}>
            <Button
              type="icon"
              icon="Facebook"
              className={classes.socialIcon}
              onClick={() =>
                openPopup(
                  `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`
                )
              }
              aria-label={intl.formatMessage({
                id: 'article.socialicons.facebook.label',
              })}
            />
            <Button
              type="icon"
              icon="Mailfilled"
              className={classes.socialIcon}
              onClick={mail}
              aria-label={intl.formatMessage({
                id: 'article.socialicons.email.label',
              })}
            />
            <Button
              type="icon"
              icon="Twitter"
              className={classes.socialIcon}
              onClick={() =>
                openPopup(
                  `https://twitter.com/intent/tweet?text=${headline} ${currentUrl}`
                )
              }
              aria-label={intl.formatMessage({
                id: 'article.socialicons.twitter.label',
              })}
            />
          </div>
        </Fade>
      </aside>
    </Hidden>
  )
}
