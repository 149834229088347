import React, { useEffect, useState, useRef, ReactElement } from 'react'
import clsx from 'clsx'

import { throttle } from 'lodash'
import 'intersection-observer'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'

import Icon from '@objects/icon'
import ToCList from './tocList'

const useStyles = makeStyles((theme) => ({
  tocRoot: {
    position: 'relative',
    maxWidth: '1104px',
    margin: 'auto',
  },
  toclist: {
    position: 'relative',
    padding: theme.spacing(16, 4, 10, 9),
    backgroundColor: theme.palette.background.default,
    '&:before': {
      position: 'absolute',
      top: theme.spacing(8),
      bottom: theme.spacing(15),
      left: theme.spacing(9),
      width: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.light,
      [theme.breakpoints.up('lg')]: {
        content: '""',
        bottom: theme.spacing(30),
        top: theme.spacing(17),
        left: theme.spacing(9),
      },
    },
  },
  backdrop: {
    zIndex: theme.zIndex.appBar,
  },
  toclistToggle: {
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    width: '100vw',
    borderRadius: theme.spacing(1),
    // overflow: 'hidden',
    transitionProperty: 'transform',
    bottom: 0,
    '&.is-open': {
      boxShadow: '0px 2px 24px 0px rgba(0, 0, 0, 0.5)',
    },

    // backgroundColor: theme.palette.primary.main,
  },
  isHidden: {
    transform: 'translateY(130%)',
  },
  toclistButton: {
    position: 'relative',
    padding: theme.spacing(2, 10, 3, 4),
    lineHeight: 1.1,
    height: '46px',
    borderRadius: theme.spacing(1),
    bottom: theme.spacing(3),
    transitionProperty: 'color',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.3)',
    left: 'calc(50% - 65px)',
    width: '115px',
    transitionDuration: theme.transitions.duration.short as string,
    transitionTimingFunction: theme.transitions.easing.easeInOut as string,
    '.is-open &': {
      // color: theme.palette.grey.default,
      display: 'none',
    },
  },
  toclistIcon: {
    fontSize: theme.spacing(7),
    position: 'absolute',
    right: theme.spacing(7),
    top: theme.spacing(15),
    zIndex: theme.zIndex.appBar,
    color: theme.palette.text.primary,
    display: 'none',
    '.is-open &': {
      display: 'block',

      top: theme.spacing(7),
      transform: 'scale(1, -1)',
      color: 'black',
      cursor: 'pointer',
    },
  },
  openIconUp: {
    transform: 'translate(-30%, -20%) scale(0.6)',
    // fontSize: '8px',
  },
  openIconDown: {
    transform: 'translate(-130%, 45%) scale(0.6)',
    // fontSize: '8px',
  },
  lines: {
    '& > div': {
      height: '2px',
      position: 'absolute',
      background: theme.palette.text.primary,
      right: theme.spacing(5),
    },
    '& > :nth-child(1)': {
      top: '15px',
      width: '50px',
    },
    '& > :nth-child(2)': {
      top: '23px',
      width: '59px',
    },
    '& > :nth-child(3)': {
      top: '31px',
      width: '50px',
    },
  },
  toclistDrawer: {
    ...theme.typography.toc,
    maxHeight: 0,
    transitionProperty: 'max-height',
    transitionDuration: theme.transitions.duration.complex as string,
    transitionTimingFunction: theme.transitions.easing.easeInOut as string,
    '.is-open > &': {
      maxHeight: 'calc(100vh - 80px)',
      overflowY: 'auto',
    },
  },
  article: {
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(20),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(30),
    },
  },
}))

type TocItem = {
  id: string
  label: string
}

export type TocProps = {
  children?: React.ReactChildren | React.ReactNode | string
  items: Array<TocItem>
}

export default function ToC({ children, items }: TocProps): ReactElement {
  const theme = useTheme()
  const classes = useStyles()
  const [itemState, setItemState] = useState(
    items?.map((item) => {
      return {
        active: false,
      }
    })
  )
  const [position, setPosition] = useState(0)

  // state mobile toggle
  const [isHidden, setIsHidden] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const observer = useRef<IntersectionObserver>()
  const ListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let curPos = 0
    if (itemState)
      itemState.forEach((item, i) => {
        if (item.active) {
          curPos = i
        }
      })

    setPosition(curPos)
  }, [itemState])

  useEffect(() => {
    if (!isDesktop) {
      let lastScrollPos = window.pageYOffset

      const throttledScrollHandler = throttle((e) => {
        const st = window.pageYOffset
        if (st > lastScrollPos) {
          setIsHidden(false)
        } else if ((st < 150 || st < lastScrollPos) && !isOpen) {
          setIsHidden(true)
        }
        lastScrollPos = st
      }, 250)

      window.addEventListener('scroll', throttledScrollHandler)

      return () => {
        window.removeEventListener('scroll', throttledScrollHandler)
      }
    }
  }, [isOpen])

  useEffect(() => {
    observer.current = new window.IntersectionObserver(handleIntersection, {
      threshold: 1.0,
      rootMargin: '0px 0px -30% 0px',
    })

    if (!items) return
    items.forEach((item, i) => {
      const hl = document.getElementById(item.id)
      if (hl) {
        hl.dataset.position = i.toString(10)
        observer.current?.observe(hl)
      }
    })

    return () => observer.current?.disconnect()
  }, [])

  function handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      const pos = parseInt(entry.target.dataset.position, 10)
      let curState = false
      if (entry.isIntersecting || entry.boundingClientRect.y < 0) {
        curState = true
      }
      setItemState((state) => {
        const newState = [...state]
        newState[pos] = { active: curState }
        return newState
      })
    })
  }

  return (
    <Grid container className={classes.tocRoot} component="section">
      <Hidden mdDown>
        <Grid item md={4} ref={ListRef} className={classes.toclist}>
          {!!items && <ToCList items={items} position={position} />}
        </Grid>
      </Hidden>
      <Hidden lgUp>
        {/*<Backdrop
          className={classes.backdrop}
          open={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        />*/}
        <div
          className={clsx(classes.toclistToggle, {
            'is-open': isOpen,
            [classes.isHidden]: isHidden,
          })}
        >
          <div
            className={classes.toclistButton}
            onClick={() => setIsOpen(!isOpen)}
          >
            {!!items && !!items[position] && (
              <>
                <Icon className={classes.openIconUp} name={'ArrowUp'} />
                <Icon className={classes.openIconDown} name={'ArrowDown'} />
                <div className={classes.lines}>
                  <div />
                  <div />
                  <div />
                </div>
              </>
            )}
          </div>
          <div className={classes.toclistDrawer}>
            <div
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <Icon
                className={classes.toclistIcon}
                name="Close"
                size="inherit"
              />
            </div>

            <div className={classes.toclist}>
              <ToCList
                items={items}
                position={position}
                clickHandler={() => setIsOpen(false)}
              />
            </div>
          </div>
        </div>
      </Hidden>
      <Grid item xs={12} lg={8} className={classes.article}>
        {children}
      </Grid>
    </Grid>
  )
}
